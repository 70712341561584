<ng-container
  *ngIf="
    profileCompleteness &&
    !profileCompleteness.isRejected &&
    (!hideMissingFields || profileCompleteness.percentage < 100)
  "
>
  <div
    class="d-flex align-items-center min-w-450 w-sm-360px w-310px flex-column"
    *ngIf="!isProfileCompleted"
  >
    <div class="d-flex justify-content-between flex-column flex-md-row w-100 mt-auto mb-2">
      <span class="fw-bold fs-6 text-gray-400">
        <span
          *ngIf="
            profileCompleteness?.percentage >= 100 &&
            !profileCompleteness?.isApprovalRequested
          "
          >Your application is ready to be submitted</span
        >

        <span class="fw-bolder text-dark" *ngIf="profileCompleteness?.percentage < 100"
          >Profile completion</span
        >

        <span *ngIf="profileCompleteness?.percentage < 100" class="fw-bolder text-primary fs-6 ms-3"
        > {{ profileCompleteness?.percentage }}%</span
      >
        <span
          class="bi bi-exclamation-circle fs-3 me-3 text-red ms-2"
          [ngbPopover]="popContent"
          *ngIf="profileCompleteness?.percentage < 100 && !hideMissingFields"
          triggers="mouseenter"
          placement="bottom"
          [popoverTitle]="popTitle"
        >
        </span>


        <ng-template #popTitle>
          <div class="d-flex justify-content-between align-items-center" style="width: 250px;">
            <span class="">Missing fields</span>
            <div class="d-flex">
            </div>
          </div>
        </ng-template>

        <span
          class="bi bi-check-circle-fill fs-3 me-3 text-success ms-2"
          ngbTooltip="Your profile is completed"
          *ngIf="
            profileCompleteness?.percentage >= 100 &&
            !profileCompleteness?.isApprovalRequested
          "
        ></span>
      </span>

      <div class="d-flex align-items-end justify-content-end">
        <div [ngbTooltip]="'Currently, the ability to edit profiles is temporarily suspended.'"
        [disableTooltip]="!isEditProfileDisabled"
        *ngIf="editProfileVisible"
        >
          <button class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-sm fs-8 "
          [routerLink]="'/startups/edit/startup-information'"
          [disabled]="isEditProfileDisabled"
          >
            <i
              class="bi bi-pencil-fill cursor-pointer text-white fs-8"
            ></i>
            <span>
              Edit Profile
            </span>
          </button>
        </div>

        <!-- *ngIf="
          isSubmitVisible
        " -->
        <!-- style="margin-top: -10px" -->
        <button
          (click)="onRequestApproval()"
          class="btn btn-success text-white ls-2 text-uppercase fs-8 fw-boldest btn-sm ms-2"
          [disabled]="isSubmitDisabled"
          *ngIf="!isUnderApproval"
        >
          Submit
        </button>
      </div>


      <span
        class="badge badge-primary fs-4 p-2 blink_me"
        *ngIf="isUnderApproval"
        ngbPopover="Profile under review by admin"
        placement="bottom"
        triggers="mouseenter:mouseleave"
      >
        <!-- ngbTooltip="Your application is under review, 1 step away from going live." -->
        <span class="bi bi-card-list fs-5 me-3 text-white ms-2"></span>
        Submitted
      </span>

    </div>
    <div
      class="h-5px mx-3 w-100 bg-light mb-3"
      *ngIf="profileCompleteness?.percentage < 100"
    >
      <div
        class="bg-primary rounded h-5px"
        role="progressbar"
        style="width: {{ profileCompleteness?.percentage }}%;"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngClass]="
          profileCompleteness?.percentage > 80
            ? 'bg-success'
            : profileCompleteness?.percentage >= 50 &&
              profileCompleteness?.percentage < 80
            ? 'bg-primary'
            : 'bg-dark'
        "
      ></div>

      <!-- <div class="bg-primary rounded h-5px" role="progressbar" style="width: {{profileCompleteness?.percentage}}%;"
        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div> -->
    </div>
  </div>

  <!-- <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
    *ngIf="profileCompleteness?.canToggleStatus">
    <div class="form-check form-switch form-check-custom form-check-solid justify-content-end w-100 ">
      <label class="form-check-label me-4" for="flexSwitchDefault">
        Raising funds
      </label>
      <input class="form-check-input" type="checkbox" value="" id="flexSwitchDefault"
        [checked]="profileCompleteness?.isRaisingFunds" (change)="onToggleChange($event)" />
    </div>
  </div> -->
</ng-container>
<ng-container *ngIf="profileCompleteness && profileCompleteness.isRejected">
  <!-- style="background-color: red" -->
  <span
    [ngStyle]="{'background': isLimitedAccess? '#181c32' : 'red'}"
    class="badge fs-4 p-3"
    [ngbPopover]="isLimitedAccess? 'Your profile has limited access.': 'Your application was rejected during the review.'"
    placement="bottom"
    [popoverTitle]="isLimitedAccess ?'Limited Access' :'Profile Rejected'"
    triggers="mouseenter:mouseleave"
  >
    <!-- ngbTooltip="Your application is under review, 1 step away from going live." -->
    <!-- <span class="bi bi-card-list fs-3 me-3 text-white ms-2 "></span> -->
   {{isLimitedAccess ? "Limited Access" :"Profile Rejected"}}
  </span>
</ng-container>

<ng-template #popContent>
  <div class="max-h-75vh overflow-auto">
    <div *ngFor="let fieldGroup of missingFields | keyvalue">
      <p class="mb-0 fw-bolder mt-2">{{fieldGroup.key}}</p>
      <ul class="list-group list-group-flush">
        <li class="list-group-item cursor-pointer" *ngFor="let field of fieldGroup.value" [routerLink]="field.url">
          <span class="bi bi-x fs-3 me-3 text-danger"></span>
          {{ field.field }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="false">
<div
  *ngIf="profileCompleteness && isProfileCompleted"
  class="d-flex"
>
<!-- *ngIf="profileData.canAddMembers" -->
  <button
  class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-md me-2"
  [routerLink]="''+startupProfilePageUrl+''">
  View Profile
  </button>

  <app-add-team-button *ngIf="!profileData.parentId"></app-add-team-button>
</div>
</ng-template>
<ng-container *ngIf="profileCompleteness && isProfileCompleted && showShareFeedback">
  <div class="d-flex align-items-center">
    <span class="text-gray-600 fs-6 max-w-140px text-align-right">
      Let the world know you are here!
    </span>

    <button class="btn btn-dark text-white ls-2 fw-boldest border-radius ms-3 text-uppercase"
      (click)="handleShareButton()">
      Share
    </button>
  </div>

</ng-container>
