
<div class="card cursor-pointer overlay border border-2 card-bordered shadow "
[ngbPopover]="popContent" container="body" [placement]="['left', 'right','top']" triggers="mouseenter:mouseleave"
[disablePopover]="!corporate.shortDescription && !corporate.briefDescription"
  popoverClass="popover-custom-width"

  (click)="openDetailsView()">
  <div class="card-body overlay-wrapper text-left p-0 cardImageSection">
    <img class="w-100 rounded" [src]="s3logoUrl" *ngIf="s3logoUrl" />

    <ngx-avatars class="w-100" [size]="ngxAvtarSize" *ngIf="!s3logoUrl"
      [name]="corporate?.name" [round]="false">
    </ngx-avatars>

    <div class="mb-0 nameSection">
      <h3 class="fw-bolder text-white fs-1x mb-2 text-truncate">
        {{ corporate.name }}
      </h3>
      <div class="row w-100" *ngIf="brandDetails?.features?.industries_technologies_section">
        <div class="col-2 text-truncate text-white w-100 fs-6 mb-2 fw-normal mt-0">
          <span class="badge badge-search-cards text-white opacity-10 fw-bold fs-7 me-3"
            *ngFor="let industry of corporate.sectoralInterests">
            {{ industry }}
          </span>
        </div>
      </div>


    </div>
  </div>

  <div class="card-footer d-flex align-items-center p-3  w-100 overflow-hidden">

    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex  align-items-center">

    <span [ngbTooltip]="corporate?.domainAreas?.join(', ')"
      class="badge  badge-secondary text-dark badge-custom-search-page fs-8 bg-white" style="max-width: 100%;">

      <ng-container *ngIf="corporate?.domainAreas?.length">
        <span class="text-truncate"> {{corporate?.domainAreas[0]}}</span>

        <span *ngIf="corporate?.domainAreas?.length > 1">&nbsp;(+{{corporate.domainAreas.length - 1}})</span>
      </ng-container>

      <ng-container *ngIf="!corporate?.domainAreas?.length">
        N/A
      </ng-container>
    </span>
  </div>
    <div *ngIf="connectionObj">
      <span [ngbTooltip]="connectionTooltip">
        <i class="bi bi-patch-check-fill fs-1 text-green header-icons-font-size"></i>
      </span>
    </div>
  </div>

  </div>
</div>
<ng-template #popContent>
  <div *ngIf="corporate.shortDescription || corporate.briefDescription">
    {{(corporate.shortDescription || corporate.briefDescription || '') | truncate:500 | decodeHtmlString}}
  </div>
</ng-template>
