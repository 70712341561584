import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CorporateInformationModel, CorporateProfileCompleteness, InvestorProfileCompleteness, MentorInformationModel, PartnerInformationModel, PartnerProfileCompleteness, ServiceProviderInformationModel, ServiceProviderProfileCompleteness } from 'src/app/core/domain';
import * as PartnerDashboardAction from 'src/app/core/state/partner-dashboard/partner.dashboard.action';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import { createSlug, getMissingFieldsMap } from '../../utils/common-methods';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareProfileModalComponent } from '../share-profile-modal/share-profile-modal.component';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getIndividualProfileCompleteness } from 'src/app/core/state/individual-profile-dashboard/individual-profile.dashboard.selectors';
import { getPartnerProfileCompleteness } from 'src/app/core/state/partner-dashboard/partner.dashboard.selectors';
import { getPartnerInfo } from 'src/app/core/state/partner-info';

@Component({
  selector: 'app-partner-profile-completeness',
  templateUrl: './partner-profile-completeness.component.html',
  styleUrls: ['./partner-profile-completeness.component.scss']
})
export class PartnerProfileCompletenessComponent implements OnInit {
  @Input() isUnderApproval = false;

  public profileCompleteness: PartnerProfileCompleteness;
  private destroyed$: Subject<void> = new Subject();
  missingFields: Record<string, Array<any>> = {};
  partnerInfo: PartnerInformationModel;
  serviceProviderProfilePageUrl
  @Input() showShareFeedback = true;
  @Input() showEditProfileBtn = true;
  brandDetails: Partial<IBrandDetails>;

  constructor(
    private readonly store: Store,
    private toastAlertService: ToastAlertService,
    private router: Router,
    public modal: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.getProfileCompleteness();
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;
      }
    })
    this.store.pipe(select(getPartnerInfo)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.partnerInfo = res.data;
        this.serviceProviderProfilePageUrl = `/partners/${this.partnerInfo.uuid}/${createSlug(this.partnerInfo.name)}`;
      }
    })


  }

  getProfileCompleteness() {
    this.store.dispatch(new PartnerDashboardAction.GetProfileCompleteness());
    this.store.pipe(select(getPartnerProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.profileCompleteness = res;
        this.isUnderApproval = this.profileCompleteness && this.profileCompleteness?.isApprovalRequested && !this.profileCompleteness?.canToggleStatus  && !this.profileCompleteness?.isApproved;

        this.getMissingFields();
        if (localStorage.getItem('approval-modal') !== 'hide') {
          this.checkAndShowModalForSubmitProfile();
        }

      }
    })
  }


  getMissingFields = () => {
    this.missingFields = getMissingFieldsMap(this.profileCompleteness, 'partners')
  }

  checkAndShowModalForSubmitProfile() {
    // return
    if (this.profileCompleteness.percentage >= 100 && !this.profileCompleteness.isApprovalRequested && !this.profileCompleteness.isRejected && !this.profileCompleteness?.isApproved) {
      Swal.fire({
        title: 'Awesome! your profile is ready to go live.',
        text: 'Once your profile is approved, you will be able to perform other actions',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Submit',
        showDenyButton: true,
        denyButtonText: 'Preview',
        denyButtonColor: '#000',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preDeny: () => {
          const slug = this.partnerInfo.name.split(' ').join('-').toLocaleLowerCase();
          const url = this.router.serializeUrl(this.router.createUrlTree(['/partners/' + this.partnerInfo.uuid + slug]));
          window.open(url, '_blank');
          return false;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestApproval();
        } else {
          if (result.isDenied) {
            return false;
          } else {
            localStorage.setItem('approval-modal', 'hide');
          }
        }
      });
    }
  }

  requestApproval() {
    console.log('requestApproval')
    this.store.dispatch(new PartnerDashboardAction.SendRequestApproval());
    setTimeout(() => {
      this.router.navigate(['/partners/edit/partner-information']);
    }, 1500);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }



  handleShareButton() {
    this.modal.open(
      ShareProfileModalComponent, { centered: true, size: 'lg' }
    )
  }


  get isProfileCompleted ( ) {
    return this.profileCompleteness?.percentage >= 100 && this.profileCompleteness?.isApproved;
  }


  get editProfileVisible() {
    return this.profileCompleteness?.percentage < 100 &&  this.showEditProfileBtn && !this.isUnderApproval
  }
  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }


  get isSubmitDisabled() {
    return !this.profileCompleteness?.canRequestApproval
    // !this.profileCompleteness?.isApprovalRequested
      // &&this.profileCompleteness?.canRequestApproval
      // && this.profileCompleteness?.percentage < 100
  }
}
