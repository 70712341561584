import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { IBrandDetails, MenuTypes } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { CommonService } from 'src/app/core/service/common.service';
import { getBrandDetails } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-protected-layout-wrapper',
  templateUrl: './protected-layout-wrapper.component.html',
  styleUrls: ['./protected-layout-wrapper.component.scss']
})
export class ProtectedLayoutWrapperComponent implements OnInit {
  brandDetails: Partial<IBrandDetails>;
  sidebarLessNonLoggedInView = false
  // isSideNav = true;
  screenWidth = window.innerWidth;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  ProfilePageUrls = [
    '/startups/profile/',
    '/investors/profile',
    '/corporates/profile',
    '/mentors/profile',
    '/service-provider/profile',
    '/search/startups',
    '/backdoor-login',
    '/request/authenticate',
    '/programs',
    '/vs-programs',
    '/certificate/verify',
    '/forms/form/submit',
    '/events/register',
    '/events/register-preview',
    '/startup-booster-kit',
    '/search/jobs',
    '/preview-program',
    '/programs/apply',
  ]

  constructor(
    private readonly store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) {
    this.sidebarLessNonLoggedInView = this.route.snapshot.data?.['sidebarLessNonLoggedInView']
    this.route.url.subscribe((url) => {
      this.sidebarLessNonLoggedInView = !!this.ProfilePageUrls.find(e => this.router.url.includes(e));
      // this.sidebarLessNonLoggedInView = this.router.url.includes('/startups/profile/')
      this.updateSidebarLessLayoutInService();
      console.log('sidebarLessNonLoggedInView', this.sidebarLessNonLoggedInView, url, this.router.url)
    })
  }

  updateSidebarLessLayoutInService() {
    this.commonService.sidebarLessNonLoggedInViewActive.next(this.sidebarLessNonLoggedInViewActive)
  }

  get sidebarLessNonLoggedInViewActive() {
    if (this.router.url.includes('/backdoor-login') || this.router.url.includes('/request/authenticate')
      || this.router.url.includes('/forms/form/submit')
    || this.router.url.includes('/certificate/verify') || this.router.url.includes('/preview-program') || this.router.url.includes('/programs/apply')
    ) {
      return this.sidebarLessNonLoggedInView
    }
    return this.sidebarLessNonLoggedInView && !this.profileData
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.handleReSize()
  }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
        this.handleReSize();
      }
    })

    this.store.pipe(select(getProfileData)).pipe(distinctUntilChanged()).subscribe((res) => {
      this.profileData = res;
      this.updateSidebarLessLayoutInService();
    })
  }

  handleReSize() {
    this.screenWidth = window.innerWidth;
  }



  get isSideNav() {
    return this.screenWidth > 600 && this.brandDetails.features.menu_type === MenuTypes.SIDE
  }
}
