import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ICorporatesSearchResponse, IInvestorSearchResponse, IMentorSearchResponse } from 'src/app/core/domain/search.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getCorporateToCompare, getInvestorsToCompare } from 'src/app/core/state/compare';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import * as CompareActions from 'src/app/core/state/compare/compare.action';
import { Router } from '@angular/router';
import { MAX_COMPARISION } from 'src/app/shared/constants/constants';
import { getProfileData } from 'src/app/core/state/profile';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import Swal from 'sweetalert2';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { ConnectionsService } from 'src/app/core/service/connections.service';
import { IBasicConnectionList } from 'src/app/modules/connections/connections.model';
@Component({
  selector: 'app-mentor-search-card',
  templateUrl: './mentor-search-card.component.html',
  styleUrls: ['./mentor-search-card.component.scss']
})
export class MentorSearchCardComponent implements OnInit, OnDestroy {



  private destroyed$: Subject<void> = new Subject();

  @Input('corporate') corporate: IMentorSearchResponse;
  public globalSettings$: Observable<IGlobalSettings>;

  globalSettings: IGlobalSettings;

  s3logoUrl = '';

  avtarUrl = '';
  isInCompareList = false;
  isMaxComparableItemsSelected = false;
  screenWidth;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  ngxAvtarSize = 180;
  brandDetails: Partial<IBrandDetails>;
  connectionObj: IBasicConnectionList;


  constructor(
    private store: Store,
    private router: Router,
    private profileService: ProfileService,
    private connectionsService: ConnectionsService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 480) {
      this.ngxAvtarSize = 180;
    } else if (this.screenWidth <= 768) {
      this.ngxAvtarSize = 230;
    }
    // else if (this.screenWidth <= 1024) {
    //   this.ngxAvtarSize = 211;
    // }
     else {
      this.ngxAvtarSize = 280;
    }

  }


  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    })

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        if(this.corporate.avatar) {
          this.s3logoUrl = res.imgKitUrl + this.corporate.avatar + '?tr=w-300,h-300';
        } else {
          this.s3logoUrl = res.assetsImgKitUrl + '/images/no-logo-uploaded.png' + '?tr=w-200,h-200';
        }
        if (this.corporate.user.avatar) {
          this.avtarUrl = res.imgKitUrl + this.corporate.user.avatar + '?tr=w-300,h-300';
        }
      }
    });
    this.handleSubscriptions();
    this.onWindowResize()
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })
    this.connectionObj = this.connectionsService.isConnected(this.corporate)
  }


  get connectionTooltip () {
    if(this.connectionObj.connectionStatus === 'accepted') {
      return `You are connected to ${this.connectionObj.companyName} since ${this.connectionObj.acceptedAt}`
    }else{
      return `Connection request pending acceptance from either side.`
    }
  }
  handleSubscriptions() {
    this.store.select(getCorporateToCompare).pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged()
    ).subscribe((list) => {
      if (list) {
        this.isInCompareList = list.includes(this.corporate.uuid);
        this.isMaxComparableItemsSelected = list.length >= MAX_COMPARISION;
      }
    })
  }

  addToCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.AddCorporateToCompare(this.corporate.uuid));
  }

  removeFromCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.RemoveCorporateFromCompareList(this.corporate.uuid));
  }


  openDetailsView() {
    if (this.profileData && this.profileService.isProfileLocked) {
      Swal.fire({
        title: 'Unauthorized Access',
        text: 'To access this profile, you need to have your profile approved.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Ok'
      }).then((result) => { });
      return;
    }


    const slug = this.corporate.name.split(' ').join('-').toLocaleLowerCase();
    const url = this.router.serializeUrl(this.router.createUrlTree(['/mentors/profile/' + this.corporate.uuid, slug]));
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
