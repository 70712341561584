import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';
import { RateExpirenceModalComponent } from 'src/app/shared/common-components/rate-expirence-modal/rate-expirence-modal.component';

@Component({
  selector: 'app-public-layout-footer-v2',
  templateUrl: './public-layout-footer-v2.component.html',
  styleUrls: ['./public-layout-footer-v2.component.scss']
})
export class PublicLayoutFooterV2Component implements OnInit {

  currentYear: string;
  host = window.location.hostname;
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();
  profileData: IProfileDetails;

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  get hideFeedback() {
    return this.router.url.includes('/programs/apply')
  }
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res
      }
    })

    this.store.pipe(select(getProfileData)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      this.profileData = res;
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  openFeedback() {
    this.modalService.open(RateExpirenceModalComponent, { size: 'md', scrollable: true, centered: true })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
